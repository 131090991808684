export const SERVICE_TOKEN = process.env.SERVICE_TOKEN
export const LAMBDA_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:4100' : process.env.LAMBDA_URL
export const MIDGARD_URL = process.env.NEXT_PUBLIC_MIDGARD_URL
export const NEXT_PUBLIC_EMAIL_DOMAIN = process.env.NEXT_PUBLIC_EMAIL_DOMAIN
export const NEXT_PUBLIC_MANTICORE_URL = process.env.NEXT_PUBLIC_MANTICORE_URL
export const MANTICORE_URL = process.env.MANTICORE_URL
export const NEXT_PUBLIC_ADMIN_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4204'
    : process.env.NEXT_PUBLIC_ADMIN_URL
export const NEXT_PUBLIC_GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
export const OPENAI_API_KEY = process.env.OPENAI_API_KEY
export const CONCORDE_URL = process.env.CONCORDE_URL
